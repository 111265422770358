import { isInitialized, FullStory } from "@fullstory/browser";

import { isSSR } from "@shared/utils/fullscriptEnv/fullscriptEnv";

export const useFullStorySessionUrl = (): string | undefined => {
  if (!isSSR() && isInitialized()) {
    return FullStory("getSession", { format: "url.now" }) ?? undefined;
  }

  return undefined;
};
