import { useFlippers } from "@shared/context/FlippersContext";
import { useAnalyticsLogEventMutation } from "@shared/data/mutations/AnalyticsLogEventMutation/AnalyticsLogEvent.mutation";
import { useFullStorySessionUrl } from "@shared/hooks/useFullStorySessionUrl/useFullStorySessionUrl";
import { useUnauthUrlFields } from "@shared/hooks/useUnauthUrlFields/useUnauthUrlFields";
import type { MultiCartType } from "@shared/types/MultiCartType";
import type { PurchaseType } from "@shared/types/PurchaseType";
import type { SourcePlatform } from "@shared/types/SourcePlatform";
import type { StorePlatform } from "@shared/types/StorePlatform";
import { getEHRInfoState } from "@shared/utils/ehrInfoStore/ehrInfoStore";

import type { AnalyticsEvent } from "./AnalyticsEvent";
import type { EmersonEntryPoint } from "./EmersonAnalyticsEntryPoint";
import type { PatientEntryPoint } from "./PatientAnalyticsEntryPoint";
import type { StoreEntryPoint } from "./StoreAnalyticsEntryPoint";
import type { UnauthEntryPoint } from "./UnauthAnalyticsEntryPoint";

type AnalyticsPayload = {
  onboarding_step?: string;
  practitioner_id?: string;
  clerk_id?: string;
  default_variant?: string;
  product_status?: string;
  origin_product_status?: string;
  brand_name?: string;
  entry_point?: StoreEntryPoint | PatientEntryPoint | EmersonEntryPoint | UnauthEntryPoint;
  explore_subheader_1?: string;
  explore_subheader_2?: string;
  explore_subheader_3?: string;
  patient_id?: string;
  protocol_plan_id?: string;
  order_id?: string;
  shipping_address_id?: string;
  store_id?: string;
  number_of_items?: number;
  number_of_products?: number;
  number_of_autoship_subscriptions?: number;
  treatment_plan_id?: string;
  treatment_plan_template_id?: string;
  multipatient_plan_id?: string;
  template_name?: string;
  content_type?: string;
  content_name?: string;
  content_tags?: string;
  daily_pack_name?: string;
  daily_pack_sku?: string;
  update_type?: string | string[];
  update_value?: string;
  has_daily_pack?: boolean;
  sku?: string;
  sku_list?: string[];
  product_id_list?: string[];
  discount?: number;
  current_onboarding_step?: string;
  account_margin_type?: string;
  account_modality?: string;
  practitioner_modality?: string;
  margin?: number;
  previous_margin?: number;
  error_message?: string;
  error_type?: "validation" | "network";
  attribute?: "view_integration" | "dismiss";
  url?: string;
  shareable_type?: string;
  lab_id?: string;
  lab_count?: number;
  lab_test_ids?: number[];
  has_message?: boolean;
  catalog_permission?: "open" | "restricted" | "private_categories" | "closed";
  modification_type?: string;
  modification_value?: string;
  fields_changed?: string[];
  page_version?: string;
  cart_count?: number;
  search_rank?: number;
  organic_search_rank?: number;
  recent_search_rank?: number;
  search_query_type?: string;
  search_session_id?: string;
  brand?: string;
  brand_list?: string[];
  contains_store_logo?: boolean;
  form_version?: string;
  signin_method?: string;
  has_treatment_plan?: boolean;
  automatic_event?: boolean;
  search_version?: number;
  event_message?: string;
  from_wellevate?: boolean;
  status_of_products?: string[];
  page_url?: string;
  target_url?: string;
  origin_url?: string;
  platform_select?: SourcePlatform.FULLSCRIPT | SourcePlatform.WELLEVATE;
  store_platform?: StorePlatform.FULLSCRIPT | StorePlatform.EMERSON;
  rx_count?: number;
  sell_price?: string;
  total_attachments_count?: number;
  category_type?: string;
  quantity?: number;
  status_of_product?: string;
  autoship_frequency?: string;
  autoship_frequencies?: string[];
  autoship_frequency_selected?: string;
  autoship_discount?: number;
  state_province?: string;
  delivery_method?: string;
  imat_attachments_count?: number;
  total_attachments?: string[];
  rx_items?: string[];
  address_selected?: string;
  ignore_address_verification?: boolean;
  selection_type?: string;
  patient_count?: number;
  dtp_cart_count?: number;
  filter?: string;
  filter_type?: string;
  account_discount?: number;
  account_business_type?: string;
  token_status?: string;
  favourite_category?: string;
  filter_value?: string;
  daily_pack_filter_value?: string;
  referrer?: string;
  resource_name?: string;
  resource_type?: string;
  migration_option?: string;
  user_id?: string;
  source_account_id?: string;
  from_plan_type?: string;
  to_plan_type?: string;
  restricted_brand_error?: boolean;
  plan_type?: string;
  plan_id?: string;
  sent_welcome_email?: boolean;
  access_link_type?: string;
  staff_count?: number;
  plan_access?: string;
  template_owner?: string;
  monthly_cost?: number;
  has_alternative_product_options?: boolean;
  origin_sku?: string;
  origin_sku_repurchase_rate?: number;
  origin_sku_monthly_unit_cost?: number;
  origin_brand_name?: string;
  first_alternative_product_sku?: string;
  first_alternative_product_repurchase_rate?: number;
  first_alternative_product_monthly_unit_cost?: number;
  second_alternative_product_sku?: string;
  second_alternative_product_repurchase_rate?: number;
  second_alternative_product_monthly_unit_cost?: number;
  third_alternative_product_sku?: string;
  third_alternative_product_repurchase_rate?: number;
  third_alternative_product_monthly_unit_cost?: number;
  smart_suggestion_skus?: string[];
  smart_suggestion_brands?: string[];
  practitioner_selected_skus?: string[];
  practitioner_selected_brands?: string[];
  metric_type?: string;
  alternative_product_rank?: number;
  selected_delivery?: string;
  from_breadcrumb_path?: string;
  to_breadcrumb_path?: string;
  action?: string;
  new_plan?: boolean;
  patient_intake_status?: string;
  supplement_count?: number;
  intake_record_id?: string;
  product_comparison_tag?: string[];
  purchase_type?: PurchaseType.ONE_TIME | PurchaseType.AUTOSHIP;
  is_prop_65?: boolean;
  accordion_headers?: string[];
  accordion_header?: string;
  section?: string;
  menu_type?: string;
  menu_category?: string;
  supplement_plan_id?: string;
  patient_intake_id?: string;
  product_type?: string;
  theme_target?: "light" | "dark";
  is_restricted?: boolean;
  is_accessible?: boolean;
  currently_taking?: boolean;
  has_patient?: boolean;
  vote_type?: string;
  feedback_type?: string;
  current_shipping_method?: string;
  current_shipping_cost?: string;
  previous_shipping_method?: string;
  previous_shipping_cost?: string;
  contains_heat_sensitive_item?: boolean;
  agreement_type?: MapAgreementType;
  can_add_to_plan?: boolean;
  category_name?: string;
  subcategory_name?: string;
  autoship_subscription_id?: string;
  autoship_subscription_ids?: string[];
  searchRank?: number;
  number_of_suggestions?: number;
  organicSearchRank?: number;
  searchQueryType?: string;
  result_count?: number;
  total_item_count?: number;
  original_brand_name?: string;
  original_product_sku?: string;
  suggested_product_sku?: string;
  optimization_category?: string;
  original_product_brand?: string;
  suggested_product_brand?: string;
  to_staff_count?: number;
  from_staff_count?: number;
  to_staff_access_type?: string;
  from_staff_access_type?: string;
  from_access_type?: string;
  to_access_type?: string;
  from_patient_count?: number;
  to_patient_count?: number;
  from_practitioner_id?: string;
  to_practitioner_id?: string;
  unassigned_patient_count?: number;
  fullstory_session_url?: string;
  alternative_product_type?: string;
  product_details_footer_section?: string;
  visible_to_patients?: boolean;
  is_substitute_product?: boolean;
  auth_and_capture?: boolean;
  sent_patient_email?: boolean;
  substitute_skus_added?: string[];
  substitute_brands_added?: string[];
  substitute_skus_removed?: string[];
  substitute_brands_removed?: string[];
  is_swapped_product?: boolean;

  // utm props
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_id?: string;
  utm_term?: string;
  utm_content?: string;
  utm_channel?: string;
  utm_adgroup?: string;
  utm_matchtype?: string;
  utm_device?: string;
  number_of_notifications_alerts?: number;

  // EMERSON
  days_since_order_placed?: number;
  estimated_ship_date_known?: boolean;
  multicart_type?: MultiCartType.ADMIN | MultiCartType.CUSTOMER;

  is_intake_store?: boolean;
  brand_names?: string[];
  all_added?: boolean;

  // fs.js props
  ehr_name?: string;
  integration_type?: string;
  rendered_height_px?: number;
  rendered_width_px?: number;

  // Labs stuff
  includes_lab_test?: boolean;
  requisition_view_type?: "download" | "email" | "link";
  panel_name?: string;
  lab_test_status?: string;
  lab_results_reviewed?: boolean;
  test_type?: string;
  test_frequency?: string;
  lab_journey_subscription_id?: string;
  includes_lab_journey?: boolean;
  biomarker?: string;
  biomarker_category?: string;
  is_lab_journey?: boolean;
  practitioner_pay?: boolean;

  passed_validation?: boolean;
  modification_types?: string[];
  avo_version?: string;
  number_of_plans?: number;
  content?: string;
  ingredient_name?: string;
};

enum SearchType {
  PRODUCT = "Product",
  INGREDIENT = "Ingredient",
  BRAND = "Brand",
  SUPPLEMENT_TYPE = "SupplementType",
  CONDITION = "Condition",
  QUERY = "query",
}

enum LabSearchType {
  TEST = "Test",
  COMPANY = "Company",
  HEALTH_CATEGORY = "HealthCategory",
  BIOMARKER = "Biomarker",
  SAMPLE_TYPE = "SampleType",
  QUERY = "query",
}

enum FilterType {
  CATEGORY = "category",
  BRAND = "brand",
  SUPPLEMENT_TYPE = "supplement_type",
  CONDITION = "condition",
  INCLUDE_INGREDIENT = "include_ingredient",
  EXCLUDE_INGREDIENT = "exclude_ingredient",
  AVAILABILITY = "availability",
  FAVORITES = "favorites",
  DEMOGRAPHIC = "demographic",
  FORM = "form",
  ALLERGENS = "allergens",
  CERTIFICATIONS = "certifications",
  PERSONAL_CARE = "personal_care",
  EQP_LEVELS = "eqp_levels",
}

enum LabFilterType {
  COMPANY = "company",
  HEALTH_CATEGORY = "health_category",
  SAMPLE_TYPE = "sample_type",
  PHLEBOTOMY_NOT_REQUIRED = "phlebotomy_not_required",
}

enum MapAgreementType {
  SINGLE = "Single",
  MULTI = "Multi",
}

interface AnalyticsSearchPayload
  extends Pick<
    AnalyticsPayload,
    | "entry_point"
    | "ehr_name"
    | "integration_type"
    | "rendered_height_px"
    | "rendered_width_px"
    | "search_version"
    | "practitioner_modality"
    | "practitioner_id"
    | "clerk_id"
  > {
  content: string;
  type: SearchType | LabSearchType | string;
}

interface AnalyticsFilterPayload
  extends Pick<
    AnalyticsPayload,
    | "entry_point"
    | "ehr_name"
    | "integration_type"
    | "rendered_height_px"
    | "rendered_width_px"
    | "search_version"
    | "practitioner_modality"
    | "practitioner_id"
    | "clerk_id"
  > {
  content: string;
  filter_type: FilterType | LabFilterType;
}

interface AnalyticsOrderPayload
  extends Pick<
    AnalyticsPayload,
    "entry_point" | "ehr_name" | "integration_type" | "rendered_height_px" | "rendered_width_px"
  > {
  split_shipment?: boolean;
  consolidated_order_status?: string;
}

interface AnalyticsEventProps {
  eventName: AnalyticsEvent;
  payload:
    | AnalyticsPayload
    | AnalyticsSearchPayload
    | AnalyticsFilterPayload
    | AnalyticsOrderPayload;
}

/**
 * `useAnalyticsLogger` is a hook that provides a function to log analytics events.
 *
 * @returns {(input: LogAnalyticsStepOptions | AnalyticsEventProps) => Promise<AnalyticsLogEventData>} logAnalyticsEvent - A function that logs analytics events.
 *
 * @example Using AnalyticsEventProps - mutation input only
 *   const logAnalyticsEvent = useAnalyticsLogger();
 *   await logAnalyticsEvent({
 *     eventName: AnalyticsEvent.STORE_PAYMENTS_SET_UP_PAYOUTS,
 *     payload: {
 *       margin: 25,
 *       account_discount: 10,
 *     },
 *   });
 *
 * @example Using LogAnalyticsStepOptions - complete mutation payload
 *   const logAnalyticsEvent = useAnalyticsLogger();
 *   void logAnalyticsEvent({
 *     variables: {
 *       input: {
 *         eventName: AnalyticsEvent.STORE_PAYMENTS_SET_UP_PAYOUTS,
 *         payload: {
 *           margin: 25,
 *           account_discount: 10,
 *         },
 *       },
 *     },
 *     onCompleted: () => navigate(url),
 *     onError: (err) => handleError(err),
 *   });
 */
const useAnalyticsLogger = () => {
  const ehrInfo = getEHRInfoState();
  const utmParams = useUnauthUrlFields();
  const [isEverestMixpanelEnabled] = useFlippers("everest_mixpanel");
  const fullStorySessionUrl = useFullStorySessionUrl();
  const [logAnalyticsStep] = useAnalyticsLogEventMutation({
    onError: error => {
      // eslint-disable-next-line no-console
      console.error("Error logging analytics event: ", error);
    },
  });

  type LogAnalyticsStepParams = Parameters<typeof logAnalyticsStep>;
  type LogAnalyticsStepOptions = LogAnalyticsStepParams[0];

  const logAnalyticsEvent = (input: AnalyticsEventProps | LogAnalyticsStepOptions) => {
    const payload = "eventName" in input ? { variables: { input } } : input;

    if (isEverestMixpanelEnabled && ehrInfo !== null) {
      payload.variables.input.payload.integration_type = ehrInfo.integrationType;
      payload.variables.input.payload.ehr_name = ehrInfo.ehrName;
    }

    if (fullStorySessionUrl) {
      payload.variables.input.payload.fullstory_session_url = fullStorySessionUrl;
    }

    payload.variables.input.payload = {
      ...payload.variables.input.payload,
      ...utmParams,
    };

    return logAnalyticsStep(payload);
  };

  return logAnalyticsEvent;
};

export {
  useAnalyticsLogger,
  SearchType,
  LabSearchType,
  FilterType,
  LabFilterType,
  MapAgreementType,
};
export type {
  AnalyticsEventProps,
  AnalyticsPayload,
  AnalyticsFilterPayload,
  AnalyticsOrderPayload,
  AnalyticsSearchPayload,
};
