import { useSearchParams } from "@shared/react-router-dom";
import { isSSR } from "@shared/utils/fullscriptEnv/fullscriptEnv";

const convertToCamelCase = (str: string) =>
  str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

const getReferrerSearchParams = () => {
  if (document.referrer) {
    const referrerUrl = new URL(document.referrer);

    return new URLSearchParams(referrerUrl.search);
  }

  return new URLSearchParams();
};

const getSessionStorageItem = (key: string): string => {
  return sessionStorage.getItem(key);
};

const storeUTMsInSession = (utms: Record<string, string>) => {
  Object.entries(utms).forEach(([key, value]) => {
    sessionStorage.setItem(key, value);
  });
};

const getUTMs = (urlParams?: URLSearchParams) => {
  if (isSSR()) return {};

  const searchParams = urlParams || new URLSearchParams(window.location.search);
  const referrerSearchParams = getReferrerSearchParams();
  const utmParamNames = [
    "utm_campaign",
    "utm_medium",
    "utm_source",
    "utm_id",
    "utm_term",
    "utm_content",
    "utm_channel",
    "utm_adgroup",
    "utm_matchtype",
    "utm_device",
  ];

  const utmParams = utmParamNames.reduce(
    (acc, param) => {
      const value =
        searchParams.get(param) || referrerSearchParams.get(param) || getSessionStorageItem(param);

      if (value) {
        acc[param] = value;
      }

      return acc;
    },
    {} as Record<string, string>
  );

  storeUTMsInSession(utmParams);

  return utmParams;
};

const useUnauthUrlFields = ({ isCamelCaseKeys } = { isCamelCaseKeys: false }) => {
  const [searchParams] = useSearchParams();
  const payload = getUTMs(searchParams);

  if (isCamelCaseKeys) {
    return Object.fromEntries(
      Object.entries(payload).map(([key, value]) => [convertToCamelCase(key), value])
    );
  }

  return payload;
};

export { useUnauthUrlFields, getUTMs };
