import { css } from "@emotion/react";

import * as typography from "../../typography";

/*
 * Method referenced from Tommy Brunn on dev.to:
 * https://dev.to/nevon/detecting-autofilled-fields-in-javascript-l46
 */

export const autoFillDetector = css`
  @keyframes onAutoFillStart {
    from {
      /**/
    }
    to {
      /**/
    }
  }

  @keyframes onAutoFillCancel {
    from {
      /**/
    }
    to {
      /**/
    }
  }

  &:-webkit-autofill {
    // Expose a hook for JavaScript when auto fill is shown.
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillStart;
    font-family: ${typography.familySansSerif};

    // Make the backgound color become yellow _really slowly_
    transition:
      background-color 600000s ease-in-out 0s,
      color 600000s ease-in-out 0s;
  }

  &:not(:-webkit-autofill) {
    // Expose a hook for JS onAutoFillCancel
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillCancel;
  }
`;
