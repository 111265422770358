import { lazy, Suspense } from "react";

import { isDevelopment } from "@shared/utils/fullscriptEnv/fullscriptEnv";

const HotReloadIndicator = lazy(() => import("./HotReloadIndicator/HotReloadIndicator"));

const LazyHotReloadIndicator = () => {
  if (!isDevelopment()) return null;

  return (
    <Suspense fallback={null}>
      <HotReloadIndicator />
    </Suspense>
  );
};

export { LazyHotReloadIndicator };
